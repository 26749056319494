<template>
    <div class="views-mainA" :style="{height:tableHeight+'px'}">
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search" style="padding:0 16px;">
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body" >
                <el-table :data="postList" stripe style="width: 100%" > 
                    <el-table-column prop="RegionName" label="区域名称"></el-table-column>
                    <el-table-column prop="SYSUSERDISPLAYNAME" label="姓名"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column prop="Mobile" label="手机号"></el-table-column>
                    <el-table-column prop="IDCard" label="身份证号"></el-table-column>
                    <el-table-column prop="ISDEACTIVATE" label="是否禁用">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISDEACTIVATE==0">否</span>
                            <span v-else style="color:#F56C6C">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column prop="CreateTime" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px" :append-to-body="true" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="姓名" prop="displayname">
                    <el-input v-model="postInfo.displayname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录名" prop="LoginName" >
                    <el-input v-model="postInfo.LoginName" autocomplete="off" :disabled="doType=='2'" placeholder="登录密码默认为123456"></el-input>
                </el-form-item>
                <el-form-item v-if="doTypeStr === '添加区域用户'" label="密码" prop="PassWord" >
                    <el-input v-model="postInfo.PassWord" autocomplete="off" :disabled="doType=='2'" placeholder="登录密码默认为123456"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="postInfo.mobile" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="idcard">
                    <el-input v-model="postInfo.idcard" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否禁用" v-if="doType=='2'">
                    <el-radio v-model="postInfo.ISDEACTIVATE" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISDEACTIVATE" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="备注" >
                    <el-input v-model="postInfo.bz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "Ruser",
  props: {
    regionid: {
      type: String,
      default: "",
    },
    tableHeight: {
      type: Number,
      default: 600,
    },
  },
  data() {
    var displayname = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    var LoginName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入登录名"));
      } else {
        callback();
      }
    };
    var mobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      }else if(!/^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/.test(value)){
            callback(new Error('手机号输入有误'));
        } else {
        callback();
      }
    };
    var idcard = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入身份证号"));
      }else if(!/^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)){
            callback(new Error('身份证号输入有误'));
        } else {
        callback();
      }
    };
    return {
        canDo:true,
        postList:[],
        PageIndex:1,
        PageSize:6,
        Total:0,

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            displayname: [{ validator: displayname, trigger: "blur" }],
            LoginName: [{ validator: LoginName, trigger: "blur" }],
            mobile: [{ validator: mobile, trigger: "blur" }],
            idcard: [{ validator: idcard, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            RegionSnowID:this.regionid,
        }
        this.$http.post("RegionUser/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            REGIONSNOWID:this.regionid,
            displayname:"",
            mobile:"",
            idcard:"",
            bz:"",
            LoginName:"",
            PassWord: '',
        }
        this.doTypeStr="添加区域用户"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("RegionUser/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var iac="0"
        if(item.ISDEACTIVATE){
            iac = item.ISDEACTIVATE.toString()
        }
        this.postInfo={
            LoginName:item.LOGINNAME,
            SNOWID:item.SnowID,
            displayname:item.SYSUSERDISPLAYNAME,
            mobile:item.Mobile,
            idcard:item.IDCard,
            bz:item.BZ,
            ISDEACTIVATE:iac,
            SYSUSERSNOWID:item.SYSUSERSNOWID,
        }
        this.doTypeStr="修改区域用户"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            REGIONSNOWID:this.regionid,
            SNOWID:this.postInfo.SNOWID,
            displayname:this.postInfo.displayname,
            mobile:this.postInfo.mobile,
            idcard:this.postInfo.idcard,
            bz:this.postInfo.bz,
        }
        this.$http.post("RegionUser/UpdateData", params)
            .then((response) => {
                if (response.data.SUCCESS) {
                   this.sendSysEdit()
                } else {
                    this.canDo=true
                    this.hideLoading()
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },
    sendSysEdit(){
        var params={
            snowid:this.postInfo.SYSUSERSNOWID,
            isdeactivate:parseInt(this.postInfo.ISDEACTIVATE),
            displayname:this.postInfo.displayname,
        }
        this.$http.post("SysUser/UpdateSysUser", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.$message.error(response.data.MSG)
                }
            }) .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除区域用户'+item.SYSUSERDISPLAYNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SnowID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("RegionUser/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>