<template>
  <div id="app" class="appMain">
    <div class="appHead">
      <div class="logoBox" @click="toBigScreen">
        <img src="statics/img/logo.png" alt="">
      </div>
      <div class="hisNavBox">
        <div class="hisNavBoxIn">
          <div class="hisNavItme" :class="nowCheckName == item ? 'hisNavItmeOn' : ''" v-for="item in historyList"
            :key="item" @click.stop="showNav(item)">
            <p class="text">{{ routerNameStr(item) }}</p>
            <p v-show="historyList.length > 1" class="close" @click.stop="hideNav(item)">×</p>
          </div>

        </div>
      </div>
      <div class="loginInfo">
        <img :src="headImg" class="headImg">
        <div class="ddBox">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="changeUserModal = true">修改资料</el-dropdown-item> -->
              <el-dropdown-item @click.native="changePwdModal = true">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="logoutModal = true">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="appLeft">
      <el-menu :default-active="defActive" background-color="#545c64" text-color="#fff" :collapse="isCollapse" router
        active-text-color="#ffd04b">
        <el-menu-item index="/AdminUserManage" v-show="sysType == '1'">
          <i class="el-icon-s-custom"></i>
          <span slot="title">系统管理员列表</span>
        </el-menu-item>
        <el-menu-item index="/AdminRegionManage">
          <i class="el-icon-menu"></i>
          <span slot="title">行政区管理</span>
        </el-menu-item>
        <el-menu-item index="/ProjectManage">
          <i class="el-icon-document"></i>
          <span slot="title">项目管理</span>
        </el-menu-item>
        <el-submenu index="Home1" v-show="sysType == '1'">
          <template slot="title">
            <i class="el-icon-notebook-1"></i>
            <span>字典项管理</span>
          </template>
          <el-menu-item index="/ServeTeamType">服务组织类型</el-menu-item>
          <el-menu-item index="/MachineType">农机类型</el-menu-item>
          <el-menu-item index="/MachineBrand">农机品牌</el-menu-item>
          <el-menu-item index="/ToolsType">农具类型</el-menu-item>
          <el-menu-item index="/ToolsBrand">农具品牌</el-menu-item>
          <el-menu-item index="/CropsType">农作物类型</el-menu-item>
          <el-menu-item index="/HandleType">作业环节类型</el-menu-item>
        </el-submenu>
        <el-menu-item index="/ServeTeamManage">
          <i class="el-icon-school"></i>
          <span slot="title">服务组织管理</span>
        </el-menu-item>
        <el-menu-item index="/ServiceOrganizationFiling">
          <i class="el-icon-school"></i>
          <span slot="title">服务组织备案管理</span>
        </el-menu-item>
        <el-menu-item index="/MachineManage">
          <i class="el-icon-truck"></i>
          <span slot="title">农机管理</span>
        </el-menu-item>
        <el-menu-item index="/ToolManage">
          <i class="el-icon-c-scale-to-original"></i>
          <span slot="title">农具管理</span>
        </el-menu-item>
        <el-menu-item index="/IMEIManage">
          <i class="el-icon-location-information"></i>
          <span slot="title">定位器管理</span>
        </el-menu-item>
        <el-submenu index="Home2">
          <template slot="title">
            <i class="el-icon-notebook-1"></i>
            <span>托管合同管理</span>
          </template>
          <el-menu-item index="/ContractList">
            <i class="el-icon-c-scale-to-original"></i>
            <span slot="title">合同列表</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="Home3">
          <template slot="title">
            <i class="el-icon-notebook-1"></i>
            <span>作业过程管理</span>
          </template>
          <el-menu-item index="/OperationList">
            <i class="el-icon-c-scale-to-original"></i>
            <span slot="title">作业列表</span>
          </el-menu-item>
          <el-menu-item index="/OperationMap">
            <i class="el-icon-c-scale-to-original"></i>
            <span slot="title">作业地图</span>
          </el-menu-item>
          <el-menu-item index="/OperationStatistics">
            <i class="el-icon-c-scale-to-original"></i>
            <span slot="title">作业统计</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="Home4">
          <template slot="title">
            <i class="el-icon-notebook-1"></i>
            <span>数据质量管理</span>
          </template>
          <el-menu-item index="/QualityControl">
            <i class="el-icon-c-scale-to-original"></i>
            <span slot="title">质量监控</span>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="Home5">
          <template slot="title">
            <i class="el-icon-notebook-1"></i>
            <span>汇总统计</span>
          </template>
          <el-menu-item index="/ContractStatistics">
            <i class="el-icon-c-scale-to-original"></i>
            <span slot="title">服务组织合同汇总</span>
          </el-menu-item>
          <el-menu-item index="/ExaminingReportManage">
            <i class="el-icon-document-copy"></i>
            <span slot="title">检测报告管理</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <!-- v-for="item in historyList" :key="item" v-show="nowCheckName == item" -->
    <div class="appRight" v-for="item in historyList" :key="item" v-show="nowCheckName == item">
      <router-view :name="item"></router-view>
    </div>
    <el-dialog title="操作提示" :visible.sync="logoutModal" width="30%" :close-on-click-modal="false">
      <span>确定退出登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="logoutModal = false">取 消</el-button>
        <el-button type="primary" @click="logoutSend">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="changePwdModal" :close-on-click-modal="false">
      <el-form :model="changePwd" status-icon :rules="pwdrules" ref="cpForm" label-width="100px" class="demo-cpForm">
        <el-form-item label="原密码" prop="oldPass">
          <el-input type="password" v-model="changePwd.oldPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input type="password" v-model="changePwd.newPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="changePwd.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPwdForm('cpForm')">提交</el-button>
          <el-button @click="changePwdModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    var validatePass0 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入原密码'));
      } else {
        callback();
      }
    };
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.changePwd.checkPass !== '') {
          this.$refs.cpForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.changePwd.newPass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      sysType: "1",

      isCollapse: false,
      loadpage: false,
      defActive: "",
      userName: "",
      headImg: "statics/img/headImg.png",


      logoutModal: false,
      changePwdModal: false,
      changePwd: {
        oldPass: '',
        newPass: '',
        checkPass: '',
      },
      pwdrules: {
        oldPass: [
          { validator: validatePass0, trigger: 'blur' }
        ],
        newPass: [
          { validator: validatePass1, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      canDo: true,

      menuList: [
        { link: 'AdminUserManage', name: '系统管理员' },
        { link: 'AdminRegionManage', name: '行政区管理' },
        { link: 'ServeTeamType', name: '服务组织类型' },
        { link: 'MachineType', name: '农机类型' },
        { link: 'MachineBrand', name: '农机品牌' },
        { link: 'ToolsType', name: '农具类型' },
        { link: 'ToolsBrand', name: '农具品牌' },
        { link: 'ToolsBrand', name: '农具品牌' },
        { link: 'CropsType', name: '农作物类型' },
        { link: 'HandleType', name: '作业环节类型' },
        { link: 'ProjectManage', name: '项目管理' },
        { link: 'ServeTeamManage', name: '服务组织管理' },
        { link: 'ServiceOrganizationFiling', name: '服务组织备案管理' },
        { link: 'MachineManage', name: '农机管理' },
        { link: 'ToolManage', name: '农具管理' },
        { link: 'IMEIManage', name: '定位器管理' },
        { link: 'ContractList', name: '合同列表' },
        { link: 'OperationList', name: '作业列表' },
        { link: 'OperationMap', name: '作业地图' },
        { link: 'OperationStatistics', name: '作业统计' },
        { link: 'QualityControl', name: '质量监控' },
        { link: 'ContractStatistics', name: '服务组织合同汇总' },
        { link: 'ExaminingReportManage', name: '检测报告管理' },
      ],
      historyList: [],
      nowCheckName: "",
      routerNameStr: function (name) {
        var str = ""
        var list = this.menuList
        for (var i = 0; i < list.length; i++) {
          if (list[i].link == name) {
            str = list[i].name
            break;
          }
        }
        return str
      }

    };
  },
  mounted() {
    this.sysType = this.$store.state.sysType
    this.loadItem = null
    this.isLoginPage(this.$route.name)

  },
  methods: {
    isLoginPage(name) {
      this.userName = this.$store.state.name
      this.defActive = '/' + name
      this.setHistoryRouters(name)
      this.loadpage = true
    },
    //退出登录
    logoutSend() {
      this.logoutModal = false
      this.logout()
    },
    //修改密码
    submitPwdForm(formName) {
      if (this.canDo) {
        this.$refs.cpForm.validate((valid) => {
          if (valid) {
            this.sendChangePwdA()
            // if (this.sysType == "1") {
            //   this.sendChangePwdA()
            // } else {
            //   this.sendChangePwdB()
            // }

          } else {
            return false;
          }
        });
      }
    },
    sendChangePwdA() {
      var params = {
        sysUserSnowID: this.$store.state.snowid,
        oldPassWord: this.changePwd.oldPass,
        newPassWord: this.changePwd.newPass,
      }
      this.canDo = false
      this.showLoading("请稍后")
      // var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("SysUser/UpdatePassWord", params)
        .then((response) => {
          this.canDo = true
          this.hideLoading()
          if (response.data.SUCCESS) {
            this.changePwdModal = false
            this.$message.success("已修改")

          } else {
            this.errorRequest(response.data.DATA, response.data.MSG, true)
          }
        })
        .catch((error) => {
          this.errorFlag(JSON.stringify(error))
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo = true
        });
    },
    sendChangePwdB() {
      var params = {
        USERSNOWID: this.$store.state.snowid,
        OLDPASSWORD: this.changePwd.oldPass,
        NEWPASSWORD: this.changePwd.newPass,

      }
      this.canDo = false
      this.showLoading("请稍后")
      // var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("MainDb/EditPassword", params)
        .then((response) => {
          this.canDo = true
          this.hideLoading()
          if (response.data.SUCCESS) {
            this.changePwdModal = false
            this.$message.success("已修改")
          } else {
            this.errorRequest(response.data.DATA, response.data.MSG, true)
          }
        })
        .catch((error) => {
          this.errorFlag(JSON.stringify(error))
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo = true
        });
    },
    toBigScreen() {
      var url = window.location.host;
      window.open("http://" + url + "/statics/bigScreen/index.html")
    },
    setHistoryRouters(name) {
      this.nowCheckName = name
      if (this.historyList.length == 0) {
        this.historyList.push(name)
      } else {
        var has = false
        for (var i = 0; i < this.historyList.length; i++) {
          if (this.historyList[i] === name) {
            has = true
            break;
          }
        }
        if (!has) {
          this.historyList.push(name)
        }
      }
    },
    showNav(name) {
      // console.log(name)
      // console.log(this.nowCheckName)
      // console.log(this.defActive)
      // this.nowCheckName = name
      // this.defActive="/"+name
      //nowCheckName
      //defActive
      if (this.nowCheckName != name) {
        this.$router.replace("/" + name);
      }
    },
    hideNav(name) {
      if (this.historyList.length > 1) {
        var nl = []
        var next = ""
        for (var i = 0; i < this.historyList.length; i++) {
          if (this.historyList[i] != name) {
            nl.push(this.historyList[i])
          } else {
            if (i > 0) {
              next = this.historyList[i - 1]
            } else {
              next = this.historyList[i + 1]
            }
          }
        }
        this.historyList = nl
        if (this.nowCheckName == name) {
          this.$router.replace("/" + next);
        }
      }
    },
  },
  computed: {

  },
  watch: {
    '$route.name': function (newVal, oldVal) {
      this.setHistoryRouters(newVal)
      this.isLoginPage(newVal)
    }
  },
};
</script>
 
<style>
.appMain {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.appHead {
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  background-color: #FFFFFF;
}

.appLeft {
  width: 240px;
  position: absolute;
  left: 0px;
  top: 56px;
  bottom: 0px;
  background-color: #545c64;
}

.appLeft .el-menu::-webkit-scrollbar {
  width: 0 !important
}

.appRight {
  position: absolute;
  left: 240px;
  bottom: 0px;
  right: 0px;
  top: 56px;
  overflow: auto;
  background-color: #F8F8F8;
  border-radius: 4px;
}

.logoBox {
  height: 55px;
  width: 240px;
  float: left;
  background-color: #545c64;
  overflow: hidden;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.logoBox img {
  width: 240px;
  display: block;
  float: left;
  margin-left: -5px;
}

.loginInfo {
  overflow: hidden;
  height: 56px;
  position: absolute;
  right: 20px;
  top: 0;
}

.loginInfo .headImg {
  width: 36px;
  float: left;
  margin-top: 10px;
}

.appHead .headCon {
  height: 56px;
  float: left;
  overflow: hidden;
}

.appHead .headCon .btnItem {
  float: left;
  margin-right: 20px;
}

.loginInfo .ddBox {
  float: left;
  margin-left: 16px;
  cursor: pointer;
  margin-top: 15px;
}

.appMain .maxpage {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0;
}

.el-menu {
  border-right: 0 !important;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .el-menu-item:hover{background-color: #FFFFFF!important;} */


.el-dialog {
  overflow: hidden;
}

.pgItem {
  margin-bottom: 20px;
}

.taskItemBox .el-dialog {
  overflow: hidden;
}

.hisNavBox {
  height: 55px;
  overflow: hidden;
  max-width: calc(100% - 500px);
}

.hisNavBoxIn {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 75px;
}

.hisNavItme {
  height: 32px;
  border: 1px solid #ECECEC;
  border-radius: 3px;
  float: left;
  margin-left: 10px;
  margin-top: 11px;
  cursor: pointer;
  padding: 0 10px;
}

.hisNavItme .text {
  height: 32px;
  color: #333;
  font-size: 12px;
  text-align: center;
  line-height: 32px;
  float: left;
}

.hisNavItme .close {
  font-size: 20px;
  margin-top: 2px;
  color: #ADADAD;
  float: left;
  margin-left: 6px;
}

.hisNavItmeOn {
  border: 1px solid #409EFF;
}

.hisNavItmeOn .text {
  color: #409EFF;
}
</style>
