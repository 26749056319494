<template>
    <div class="views-main">
        <el-row style="width: 100%;height: 100%;">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <div class="views-main-title">
              <p class="txt">系统管理员列表</p>
            </div>
            <div class="tableBox" style="top:50px;">
              <div class="views-main-search">

                <el-input v-model="tname" placeholder="请输入姓名" class="iw" ></el-input>
                <el-input v-model="lname" placeholder="请输入登录名" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>

              </div>
              <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                  <el-table-column prop="DISPLAYNAME" label="姓名"></el-table-column>
                  <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                  <el-table-column prop="ISDEACTIVATE" label="是否禁用">
                    <template slot-scope="scope">
                      <span v-if="scope.row.ISDEACTIVATE==0">否</span>
                      <span v-else style="color:#F56C6C">是</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                      <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                      <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="登录名" prop="loginname" >
                    <el-input v-model="postInfo.loginname" autocomplete="off" :disabled="doType=='2'"></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="displayname">
                    <el-input v-model="postInfo.displayname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否禁用" v-if="doType=='2'">
                    <el-radio v-model="postInfo.isdeactivate" label="1">是</el-radio>
                    <el-radio v-model="postInfo.isdeactivate" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="密码" prop="password" v-if="doType=='1'">
                    <el-input v-model="postInfo.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "name",
  data() {
    var displayname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    var loginname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入登录名"));
      } else {
        callback();
      }
    };
    var password = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    


    
    return {
        
        canDo:true,
        showPagination:false,
        lname:"",
        tname:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            loginname: [{ validator: loginname, trigger: "blur" }],
            password: [{ validator: password, trigger: "blur" }],
            displayname: [{ validator: displayname, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            TYPE:1,
            LOGINNAME:this.lname,
            DISPLAYNAME:this.tname,
        }
        this.$http.post("SysUser/GetSysUserList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            displayname:"",
            loginname:"",
            password:"",
        }
        this.doTypeStr="添加管理员"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("SysUser/InsertSysUser", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        console.log(item)
        var jy=''
        if(item.ISDEACTIVATE){
            jy=item.ISDEACTIVATE.toString()
        }
        this.postInfo={
            snowid:item.SNOWID,
            loginname:item.LOGINNAME,
            isdeactivate:jy,
            displayname:item.DISPLAYNAME,
        }
        this.doTypeStr="修改管理员"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            snowid:this.postInfo.snowid,
            isdeactivate:parseInt(this.postInfo.isdeactivate),
            displayname:this.postInfo.displayname,
        }
        this.$http.post("SysUser/UpdateSysUser", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            if(item.LOGINNAME == this.$store.state.loginName){
                this.$message.error("不能删除当前登录账号") 
            }else{
                this.$confirm('确定删除管理员：'+item.DISPLAYNAME+'？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")    
                });
            }
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("SysUser/DeleteSysUser", {SNOWID:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>